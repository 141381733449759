const btnMenu = document.getElementById('header__button')
const menu = document.getElementById('header__menu')
const header = document.getElementById('header');

// Activacion del menu
btnMenu.addEventListener('click', () => {
	btnMenu.classList.toggle('header__button--show')
	menu.classList.toggle('header__menu--show')
	header.classList.toggle('header--scroll--menu')
})

// Scroll para Menu
window.addEventListener('scroll', ()=>{
	if(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20){
		header.classList.add('header--scroll')
	}else{
		header.classList.remove('header--scroll')
	}
})